import React, { useState, useEffect } from "react"
import Axios from "axios"
const RECENT_POST_API_URL = process.env.RECENT_POST_API_URL

const RecentList = () => {
  const [recentList, setRecentList] = useState([])

  useEffect(() => {
    Axios.get(RECENT_POST_API_URL)
      .then(result => {
        setRecentList(result.data)
      })
      .catch(err => {})
  }, [])

  return (
    <div className="lg:grid lg:grid-cols-3 gap-8">
      {recentList.map((post) => {
        return (
          <div
            className="rounded-xl shadow-lg overflow-hidden hover:shadow-xl mb-4 lg:mb-0 bg-white"
            key={post.id}
          >
            <a
              href={`${process.env.BLOG_ROOT_URL}${post.fields.meta.slug}`}
              target="_blank"
              itemProp="url"
              className="hover:opacity-80"
            >
              <div className="bg-gray-25">
                <img
                  src={`${process.env.SITE_ROOT_URL}${post.frontmatter.cover.childImageSharp.fixed.src}`}
                  alt={post.frontmatter.title}
                />
              </div>
              <div className="p-4 lg:p-6 bg-white">
                <div className="text-textGray-60 text-xs lg:text-sm mb-1">
                  {post.frontmatter.date}
                </div>
                <h2 className="text-sm lg:text-base font-bold text-brandNavy hover:opacity-80">
                  {post.frontmatter.title}
                </h2>
              </div>
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default RecentList
