import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ArticleCard = ({ title, date, cover, path, staticImageUrl }) => {
  return (
    <a
      href={path}
      target="_blank"
      itemProp="url"
      className="rounded-xl shadow-lg overflow-hidden hover:shadow-xl lg:flex bg-white hover:opacity-90"
    >
      <div className="lg:w-1/2 items-stretch bg-gray-25">
        {cover && (
          <GatsbyImage
            image={getImage(cover)}
            alt={title}
            className=""
            objectFit="cover"
          />
        )}
        {staticImageUrl && (
          <img src={staticImageUrl} alt={title} />
        )}
      </div>
      <div className="p-4 lg:p-5 lg:w-1/2 flex-grow-0">
        <h2 className="text-sm lg:text-sm font-bold text-brandNavy hover:opacity-80">
          {title}
        </h2>
      </div>
    </a>
  )
}

export default ArticleCard

