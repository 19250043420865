import React, { useState } from "react"
import "./accordion.css"

const Accordion = ( props ) => {
  // const [現在の状態, 更新関数] = useState(初期値)
  const [open, setOpen] = useState(false); // 初期値はfalseにして、初期状態では非表示にする、useStateに初期値(false)を渡した結果がopen、setOpenになる

  // const clickOpen = () => { // clickOpen関数を定義（開くだけ）
  //   setOpen(true) // setOpen（更新関数）をtrueにする処理
  // }

  // const clickClose = () => { // clickClose関数を定義（閉じるだけ）
  //   setOpen(false) // setOpen（更新関数）をtrueにする処理
  // }

  // const toggleButton = () => { // clickButton関数を定義（1つのボタンで開閉）
  //   if(open === true) {
  //     setOpen(false); // setOpen（更新関数）をfalseにする処理
  //   } else {
  //     setOpen(true); // setOpen（更新関数）をtrueにする処理
  //   } 
  // }

  // 又は（↑と↓どっちでも同じ動き）

  // prevStateはuseStateの更新関数で使える特殊な値
  // precStateは更新前のstateを引数として受け取る
  // 下記だと、変更前(prevState)で受け取った値を、反転（!）して、trueとfalseを反転してreturnしますよって意味
  const toggleButton = () => {
    setOpen(prevState => !prevState);
  }

  let modal; // 空の変数を用意
  if (open) { // openがtrueのときに処理が実行される。これはif(true)のときに実行され、ボタンがクリックされた際には、openはtrueになるのでif(true)になり実行される仕組み
    modal = (
      <dd className="faqcontents">
        <p className="text-xs lg:text-base leading-relaxed lg:leading-relaxed mb-4 mb-5">
          {props.contents}
        </p>
        {props.textlink}
      </dd>
    );
  }

  return (
    <dl className="accordion">
      <div className="item">
        {/* <dt className='menu pointer' onClick={clickOpen}>{props.menu}</dt>
        <dt className='menu pointer' onClick={clickClose}>{props.menu}</dt> */}

        <dt className="menu" onClick={toggleButton}>{props.menu}
          <svg
            width="20"
            height="20"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="svg-design"
          >
            <path
              d="M7.14601 3.64511L4.99951 5.79161L2.85301 3.64511L2.14601 4.35211L4.99951 7.20561L7.85301 4.35211L7.14601 3.64511Z"
              fill={"#0A293B"}
            />
          </svg>
        </dt>
        {/* 又は（↑と↓どっちでも同じ動き） */}
        {/* <dt className='menu pointer' onClick={() => toggleButton()}>{props.menu}</dt> */}
        {modal} 
      </div>
    </dl>
  );
}

export default Accordion;